<template>
  <v-container style="max-width: 450px">
    <v-form ref="getStartedForm" v-model="getStartedFormIsValid">
      <v-card tile>
        <v-card-title class="display-1 mb-4">Create Your Account</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                required
                autofocus
                ref="firstName"
                name="firstName"
                label="First Name"
                v-model="firstName"
                :rules="rules.name"
                :disabled="creatAcctPending"
              ></v-text-field>
            </v-col>

            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                required
                ref="lastName"
                name="lastName"
                label="Last Name"
                v-model="lastName"
                :rules="rules.name"
                :disabled="creatAcctPending"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            required
            ref="email"
            name="email"
            label="E-Mail"
            v-model="email"
            :rules="rules.email"
            :disabled="creatAcctPending"
          ></v-text-field>

          <v-text-field
            required
            ref="phoneNumber"
            name="phone"
            label="Phone Number"
            v-mask="phoneMask"
            v-model="phoneNumber"
            :rules="rules.phone"
            :disabled="creatAcctPending"
          ></v-text-field>

          <v-text-field
            required
            name="password"
            label="Password"
            ref="passwordInput"
            v-model="password"
            :rules="rules.pwd"
            :type="pwdOneViewable ? 'password' : 'text'"
            :disabled="creatAcctPending"
            :append-icon="pwdOneViewable ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (pwdOneViewable = !pwdOneViewable)"
            @keydown="passwordKeydown($event)"
          ></v-text-field>

          <v-text-field
            required
            name="password"
            label="Confirm Password"
            v-model="confirmPassword"
            :rules="[confirmPasswordRequired, checkPwdMatch]"
            :type="pwdTwoViewable ? 'password' : 'text'"
            :disabled="creatAcctPending"
            :append-icon="pwdTwoViewable ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (pwdTwoViewable = !pwdTwoViewable)"
            @keydown="passwordKeydown($event)"
          ></v-text-field>

          <div>
            <v-checkbox v-model="termsCheckbox" persistent-hint :rules="rules.checkbox" color="success">
              <template v-slot:label>
                <div>
                  I agree to the
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a target="_blank" href="terms-and-conditions" @click.stop v-on="on" class="mainTextColor--text">
                        Terms and Conditions
                      </a>
                    </template>
                    Click to open document in a new window
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <div class="overline">
              By registering, you agree to the processing of your personal data by The Title Girl, LLC. as described in the terms and
              conditions
            </div>
          </div>

                  <div class="text-left mt-10">    
             <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a href="/sign-in" @click.stop v-on="on" class="mainTextColor--text">
Already have an account? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                  </a>
                    </template>
                    Click to sign in to your account
                  </v-tooltip>      
       
            <v-btn
              type="submit"
              color="success"
              :loading="creatAcctPending"
              :disabled="!getStartedFormIsValid"
              @click.native.prevent="createAccount()"
            >
              Create Account
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask';
import VueRouter from '../../router';

// this is for the timeout to send a partial form
let timeout = 0;




export default {
  directives: {
    mask,
  },
  created() {},
  data: function () {
    return {
      // formData: {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      password: null,
      confirmPassword: null,
      termsCheckbox: false,
      termsTimestamp: null,
      // },
      pwdOneViewable: true,
      pwdTwoViewable: true,
      getStartedFormIsValid: false,
      creatAcctPending: false,
      phoneMask: '(###) ###-####',

      rules: {
        name: [
          (v) => !!v || 'Name is required',
          (v) => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
          (v) => (v && v.length <= 50) || 'Name must be less than 50 characters',
        ],
        email: [
          (v) => !!v || 'E-mail is required',
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        ],
        phone: [(v) => (v && v.length === 14) || 'Phone number must be 10 numbers long'],
        pwd: [
          (v) => !!v || 'Password required',
          (v) => (v && v.length >= 5) || 'Password must have at least 5 characters',
          (v) => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
          (v) => /(?=.*\d)/.test(v) || 'Must have one number',
          (v) => /([!@#$%^&*()?-_])/.test(v) || 'Must have one special character "! @ # $ % & * ( ) ? - _"',
        ],
        pwdConfirm: [
          (v) => !!v || 'Confirm password required',
          // (v) => v === this.password || "Passwords do not match"
        ],
        checkbox: [(v) => !!v || 'You must agree to continue!'],
      },
    };
  },
  watch: {
    firstName() {
      this.checkPartialForm();
    },
    lastName() {
      this.checkPartialForm();
    },
    email() {
      this.checkPartialForm();
    },
    phoneNumber() {
      this.checkPartialForm();
    },
    termsCheckbox(value, previous) {
      if (value) {
        this.termsTimestamp = Date.now();
      } else {
        this.termsTimestamp = null;
      }
    },
    password(val) {
      val.replace(/(^[a-zA-Z\s0-9!@#$%^&*?_()-])/g, '');
      this.password = val;
    },
    confirmPassword(val) {
      val.replace(/(^[a-zA-Z\s0-9!@#$%^&*?_()-])/g, '');
      this.confirmPassword = val;
    },
  },
  methods: {
    checkPartialForm() {
      clearTimeout(timeout);
      const fnValid = this.$refs.firstName.validate();
      const lnValid = this.$refs.lastName.validate();
      const emValid = this.$refs.email.validate();
      const phValid = this.$refs.phoneNumber.validate();

      if (fnValid && lnValid && (emValid || phValid) && (emValid || !this.email) && (phValid || !this.phoneNumber)) {
        const firstName = this.firstName;
        const lastName = this.lastName;
        const email = this.email || 'a'; // email cannot be empty
        const phoneNumber = this.phoneNumber;
        const payload = {
          firstName,
          lastName,
          email,
          phoneNumber,
        };

        timeout = setTimeout(() => {
          this.$store
            .dispatch('getStarted/storePartialForm', payload)
            .then(console.log('')) //res => this.$store.commit('snackmsg/show', { text: 'Partial form data stored' }))
            .catch((err) => {
              console.log(err); // this.$store.commit('snackmsg/show', { text: err, color: 'error'});
            });
        }, 1000);
      }
    },
    createAccount() {
      this.creatAcctPending = !this.creatAcctPending;
      const payload = {
        // TandCReviewed
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        password: this.password,
        confirmPassword: this.confirmPassword,
        termsTimestamp: this.termsTimestamp,
      };
      this.$store
        .dispatch('getStarted/createAccount', this)
        .then((res) => {
          this.creatAcctPending = false;
          this.$store.commit('snackmsg/show', { text: 'Your account has been created!' });

          rdt('track', 'SignUp', {
          "currency": "USD",
          "transactionId": "12345678",
          "value": 100
    });

          VueRouter.push({ name: 'app.dashboard' });
        })
        .catch((err) => {
          this.creatAcctPending = false;
          this.password = null;
          this.confirmPassword = null;
          this.$refs.passwordInput.focus();
          this.$store.commit('snackmsg/show', { text: err, color: 'error' });
        });
    },
    passwordKeydown(e) {
      if(e.keyCode > 48) {
        if (!/(^[a-zA-Z\s0-9!@#$%^&*?_()-])$/.test(e.key)) {
          e.preventDefault();
        }
      }
    },
  },
  computed: {
    checkPwdMatch() {
      return this.password === this.confirmPassword || 'Passwords must match';
    },
    confirmPasswordRequired() {
      return !!this.confirmPassword || 'Confirm password required';
    },
  },
};
</script>
